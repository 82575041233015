<template>
    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true">

                <!-- <el-form-item>
                    <el-input clearable v-model="data.order_no" placeholder="请输入订单编号" />
                </el-form-item> -->


                <el-form-item label="发票审核状态">
                    <el-select clearable v-model="data.type" placeholder="请选择类型" class="SelfSelect">
                        <el-option :label="item.name" :value="item.id" v-for="item in orderStatusList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>


                <el-form-item label="申请类型">
                    <el-select clearable v-model="data.apply_type" placeholder="请选择类型" class="SelfSelect">
                        <el-option :label="item.name" :value="item.id" v-for="item in applyTypeList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>


                <el-form-item label="发票类型">
                    <el-select clearable v-model="data.invoice_type" placeholder="请选择类型" class="SelfSelect">
                        <el-option :label="item.name" :value="item.id" v-for="item in invoiceTypeList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>


                <el-form-item>

                    <el-button type="primary" @click="search()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <!-- <el-button type="primary" @click="addItem">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加会员卡
                    </el-button> -->

                </el-form-item>

            </el-form>
        </div>

        <el-table class="el-tableII FanfuTableBox" :data="list" stripe height="100%" border ref="multipleTable">

            <el-table-column show-overflow-tooltip type="selection" width="40" align="center" fixed="left" />
            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />

            <el-table-column show-overflow-tooltip prop="img" label="发票类型" align="center" width="100">
                <template slot-scope="scope">
                    {{invoiceType(scope.row.invoice_type)}}
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="img" label="开票状态" align="center" width="100">
                <template slot-scope="scope">
                    <!-- {{orderStatus(scope.row.status)}} -->


                    <el-button type="warning" v-if="scope.row.status==0">待审核</el-button>
                    <el-button type="primary" v-if="scope.row.status==1">待开票</el-button>
                    <el-button type="success" v-if="scope.row.status==2">已开票</el-button>
                    <el-button type="danger" v-if="scope.row.status==3">已拒绝</el-button>


                </template>
            </el-table-column>



            <el-table-column show-overflow-tooltip prop="img" label="申请类型" align="center" width="100">
                <template slot-scope="scope">
                    {{applyType(scope.row.apply_type)}}
                </template>
            </el-table-column>




            <el-table-column show-overflow-tooltip prop="total_price" label="发票金额" align="left" />

            <el-table-column show-overflow-tooltip prop="invoice_code" label="纳税识别号" align="left" />





            <el-table-column show-overflow-tooltip label="操作" align="center" width="250">
                <template slot-scope="scope">
                    <div class="operate1">

                        <el-button type="" class="white" @click="info(scope.row)">
                            详情
                        </el-button>



                        <!-- <el-radio v-model="upDatavalue.status" label="1">通过</el-radio>
                        <el-radio v-model="upDatavalue.status" label="3">拒绝</el-radio> -->


                        <el-button type="" class="blue" v-if="scope.row.status==0" @click="invoiceExamine(scope.row)">
                            审核
                        </el-button>




                        <el-button type="" class="dirtyblue" v-if="scope.row.status==1"
                            @click="invoiceUpload(scope.row)">
                            上传
                        </el-button>

                        <!-- 
                        <el-button type="" class="pink" @click="del(scope.row)">
                            删除
                        </el-button> -->

                        <!-- 
                        <el-button type="" class="green" @click="">
                            复制
                        </el-button>
                        <el-button type="" class="dirtyblue" @click="">
                            上移
                        </el-button>
                        <el-button type="" class="dirtybown" @click="">
                            下移
                        </el-button>
                        -->

                    </div>
                </template>
            </el-table-column>

        </el-table>

        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.page_size" :total="data.total" @current-change="handleCurrentChange" />

        <invoicepop ref="invoicepop" @refresh="getMedicalWasteTypes" />


        <invoiceExamine ref="invoiceExamine" @refresh="getMedicalWasteTypes" />


    </div>
</template>

<script>
    import invoicepop from './components/invoicepop'
    import invoiceExamine from './components/invoiceExamine'



    import resizeDetector from 'element-resize-detector'


    export default {
        components: {
            invoicepop,
            invoiceExamine
        },
        data() {
            return {

                data: {
                    dep_id: "",
                    collector_id: "",
                    status: "",
                    apply_type: "",
                    invoice_type: "",

                    page: 1,
                    page_size: 15,
                    total: 0,
                },

                list: [],

                // // 类型：1年、2月、3日
                // timeTypt: [{
                //     id: 1,
                //     name: '年',
                // }, {
                //     id: 2,
                //     name: '月',
                // }, {
                //     id: 3,
                //     name: '日',
                // }, ]


                // 状态（ 0 待审核、 1 待开票、 2 已开票、 3 已拒绝, 4 已关闭）
                orderStatusList: [{
                    id: 0,
                    name: '待审核',
                }, {
                    id: 1,
                    name: '待开票',
                }, {
                    id: 2,
                    name: '已开票',
                }, {
                    id: 3,
                    name: '已拒绝',
                }, {
                    id: 4,
                    name: '已关闭',
                }, ],


                // 1 菜单 地址 图标
                // 2 测试 走流程 微信小程序 PC端
                // 3 老版 小程序 按钮 授权书： 弹窗 授权某某某 确定
                // 2023 年9月1日20: 13: 00


                // 申请类型（0个人、1企业）
                applyTypeList: [{
                    id: 0,
                    name: '个人',
                }, {
                    id: 1,
                    name: '企业',
                }, ],

                // 发票类型（0增值税普通电子发票、1增值税普通纸质发票、2增值税专业纸质发票）

                invoiceTypeList: [{
                    id: 0,
                    name: '增值税普通电子发票',
                }, {
                    id: 1,
                    name: '增值税普通纸质发票',
                }, {
                    id: 2,
                    name: '增值税专业纸质发票',
                }, ]

            }
        },

        computed: {
            // ...mapState('m_item', ['MedicalWasteItem']),
        },

        created() {
            // this.getList()
            this.modifyLayout();
        },

        mounted() {
            this.modifyLayout();
            this.getMedicalWasteTypes()
        },

        watch: {

            "data": {
                deep: true,
                handler: function(newVal, oldVal) {


                }
            },

        },

        methods: {
            // ...mapMutations('m_item', ['setMedicalWasteItem']),

            orderStatus(type) {
                // 状态（ 0 待审核、 1 待开票、 2 已开票、 3 已拒绝, 4 已关闭）
                switch (type) {
                    case 0:
                        return "待审核"
                        break;
                    case 1:
                        return "待开票"
                        break;
                    case 2:
                        return "已开票"
                        break;
                    case 3:
                        return "已拒绝"
                        break;
                    default:
                        return "已关闭"
                        break;
                }
            },

            applyType(type) {
                // 申请类型（0个人、1企业）
                switch (type) {
                    case 0:
                        return "个人"
                        break;
                    default:
                        return "企业"
                        break;
                }
            },

            invoiceType(type) {
                // 发票类型（0增值税普通电子发票、1增值税普通纸质发票、2增值税专业纸质发票）
                switch (type) {
                    case 0:
                        return "增值税普通电子发票"
                        break;
                    case 1:
                        return "增值税普通纸质发票"
                        break;
                    default:
                        return "增值税专业纸质发票"
                        break;
                }
            },



            getMedicalWasteTypes() {

                this.$http.get('/api/invoice', this.data).then((res) => {

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                    if (res.code == 200) {

                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        this.list = res.data.data
                        this.data.total = res.data.total

                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },


            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);

                this.data.page = val

                this.getMedicalWasteTypes()
            },


            search() {
                this.data.page = 1
                this.getMedicalWasteTypes()
            },



            addItem() {
                this.$refs.memberType.open()
                // this.$refs.memberTicket.open()

            },


            edit(row) {
                console.log(row)
                this.$refs.memberType.edit(row)

                // this.$refs.memberTicket.edit(row)

            },
            invoiceExamine(row) {
                console.log(row)

                this.$refs.invoiceExamine.open(row)
                // this.$refs.invoiceExamine.edit(row)
            },

            invoiceUpload(row) {
                console.log(row)

                this.$refs.invoiceExamine.edit(row)
            },



            switchStatus(row) {

                // this.$http.put('/api/renew/setIsPublic/' + row.id, {

                // }).then((res) => {
                //     // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                //     if (res.code != 200) { //请求错误
                //         row.status = a
                //         this.$message.error(res.msg);
                //     }
                //     if (res.code == 200) {
                //         this.$message.success(res.msg);
                //         // this.close()
                //     }
                // }).catch((err) => {
                //     console.log(err)
                // });

            },


            info(row) {
                console.log(row)

                this.$refs.invoicepop.open(row)

            },



            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;

                    var a = 141 + 60 - 35 + height - 30;
                    if ($(".level_indexI .FanfuTableBox")[0]) {
                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');
                    }

                    var b = 141 + 60 + 33 + height - 30;
                    if ($(".level_indexI .el-table")[0]) {
                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');
                    }

                });

            }

        },

    }
</script>

<style lang="scss" scoped>
    .operate1 {
        // width: 400px;
        // display: block;

        // border: 1px red solid;
        // box-sizing: border-box;

        .el-button {
            color: #fff;
            border: none;
            padding: 0 20px !important;
        }

        .white {
            color: #606266;
            border: 1px #DCDFE6 solid;
            box-sizing: border-box;
        }

        .blue {
            background-color: #768DFC;
        }

        .green {
            background-color: #5BD995;
        }

        .dirtyblue {
            background-color: #4EBBC8;
        }

        .dirtybown {
            background-color: #B99216;
        }

        .pink {
            background-color: #FF754C;
        }

    }
</style>


<style scoped>
    /*  .level_indexI .FanfuTableBox {
    border: 1px blue dotted;
    box-sizing: border-box;
  }

  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini {
    border: 1px red dotted;
    box-sizing: border-box;
  } */


    /* .FanfuTableBox {
        border: 1px red dotted;
        box-sizing: border-box;
    } */
</style>
<template>

    <!-- :title="isEdit?'编辑会员类型':'新增会员类型'" -->

    <el-dialog :close-on-click-modal="false" title="发票详情" :visible.sync="show" width="600px" class="selfInputBox">

        <div class="infoContentBox">

            <p class="textLine">
                <span class="title">发票类型</span>
                <span class="content">{{invoiceType(upDatavalue.invoice_type)}}</span>
            </p>
            <p class="textLine">
                <span class="title">发票状态</span>
                <span class="content">{{orderStatus(upDatavalue.status)}}</span>
            </p>
            <p class="textLine">
                <span class="title">发票金额</span>
                <span class="content">{{upDatavalue.total_price}}</span>
            </p>


            <p class="textLine">
                <span class="title">关联订单</span>
                <span class="content orderList">

                    <div v-for="(item,index) in upDatavalue.order" :key="index" class="order">
                        <carCell :item="item"></carCell>
                    </div>

                    <!-- <view v-for="(item,index) in list" :key="index" @click="itemSel(item)">
                        <carCell :item="item"></carCell>
                    </view> -->

                </span>
            </p>



            <p class="textLine">
                <span class="title">发票台头</span>
                <span class="content">{{upDatavalue.invoice_title}}</span>
            </p>
            <p class="textLine">
                <span class="title">纳税识别号</span>
                <span class="content">{{upDatavalue.invoice_code}}</span>
            </p>
            <p class="textLine">
                <span class="title">拒绝原因</span>
                <span class="content">{{upDatavalue.refuse_reason}}</span>
            </p>
            <p class="textLine">
                <span class="title">申请时间</span>
                <span class="content">{{upDatavalue.create_time}}</span>
            </p>
            <p class="textLine">
                <span class="title">开票时间</span>
                <span class="content">{{upDatavalue.update_time}}</span>
            </p>

            <!-- <p class="textLine">
                <span class="title">类型封面</span>
                <span class="content">
                    <img src="" alt="" v-bind:src="GLOBAL.BASE_URL + upDatavalue.renewlog.img" class="Image">
                </span>
            </p> -->

            <p class="textLine back">
                发票附件
            </p>

            <img @click="viewPicture" class="invoiceList" v-if="upDatavalue.electronic_invoice"
                :src="GLOBAL.BASE_URL + upDatavalue.electronic_invoice" alt="发票">

            <p class="textLine" v-else>
                无
            </p>

        </div>

    </el-dialog>
</template>

<script>
    import carCell from './carCell.vue'


    export default {

        components: {
            carCell
        },

        model: {
            prop: 'value',
            event: 'change'
        },
        props: {

        },
        data() {
            return {
                show: false,

                upDatavalue: {},
                // is_public: 1

                // // 类型：1年、2月、3日
                // timeTypt: [{
                //     id: 1,
                //     name: '年',
                // }, {
                //     id: 2,
                //     name: '月',
                // }, {
                //     id: 3,
                //     name: '日',
                // }, ],

                // isEdit: false,

                // // 回显图片的路径
                // photoList: [],
                // // 图片的路径
                // dialogImageUrl: "",

                // timeRange: '',

            }
        },

        watch: {
            show(value) {
                if (this.show) {} else {
                    // this.isEdit = false
                    this.upDatavalue = {}
                }
            },
        },

        computed: {
            // Headers() {
            //     let token = localStorage.getItem("token");
            //     return {
            //         token
            //     }
            // }
        },

        methods: {


            orderStatus(type) {
                // 状态（ 0 待审核、 1 待开票、 2 已开票、 3 已拒绝, 4 已关闭）
                switch (type) {
                    case 0:
                        return "待审核"
                        break;
                    case 1:
                        return "待开票"
                        break;
                    case 2:
                        return "已开票"
                        break;
                    case 3:
                        return "已拒绝"
                        break;
                    default:
                        return "已关闭"
                        break;
                }
            },

            applyType(type) {
                // 申请类型（0个人、1企业）
                switch (type) {
                    case 0:
                        return "个人"
                        break;
                    default:
                        return "企业"
                        break;
                }
            },

            invoiceType(type) {
                // 发票类型（0增值税普通电子发票、1增值税普通纸质发票、2增值税专业纸质发票）
                switch (type) {
                    case 0:
                        return "增值税普通电子发票"
                        break;
                    case 1:
                        return "增值税普通纸质发票"
                        break;
                    default:
                        return "增值税专业纸质发票"
                        break;
                }
            },


            payStatus(type) {
                // 支付状态 (0未支付，1已支付,2已退款,3部分退款),
                switch (type) {
                    case 0:
                        return "未支付"
                        break;
                    case 1:
                        return "已支付"
                        break;
                    case 2:
                        return "已退款"
                        break;
                    default:
                        return "部分退款"
                        break;
                }
            },

            // orderStatus(type) {
            //     // status 0完成 1已完成

            //     switch (type) {
            //         case 0:
            //             return "完成"
            //             break;
            //         default:
            //             return "已完成"
            //             break;
            //     }
            // },

            // open(val) {
            //     console.log("！！！！！！！！！！！！！！！！", val);
            //     // this.upDatavalue = {
            //     //     is_public: 1
            //     // }
            //     this.show = true
            // },

            async open(val) {

                this.$http.get('/api//invoice/' + val.id).then((res) => {

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                    if (res.code == 200) {

                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        this.show = true

                        this.upDatavalue = res.data

                        // this.dialogImageUrl = this.upDatavalue.img

                        // this.timeRange = [String(this.upDatavalue.start_time), String(this.upDatavalue
                        //     .end_time)]

                        // this.photoList = [{
                        //     url: this.GLOBAL.BASE_URL + this.dialogImageUrl
                        // }]

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },


            viewPicture() {

                // <img @click="viewPicture" class="invoiceList" v-if="upDatavalue.electronic_invoice"
                //               :src="GLOBAL.BASE_URL + upDatavalue.electronic_invoice" alt="发票">

                if (this.upDatavalue.electronic_invoice) {

                    window.open(this.GLOBAL.BASE_URL + this.upDatavalue.electronic_invoice);
                }

            }



            // save() {
            //     // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
            //     // // 回显图片的路径
            //     // photoList: [],
            //     // // 图片的路径
            //     // dialogImageUrl: "",
            //     // console.log(this.photoList);
            //     // console.log(this.dialogImageUrl);
            //     // console.log(this.timeRange);
            //     // return
            //     this.upDatavalue.img = this.dialogImageUrl
            //     this.upDatavalue.start_time = this.timeRange[0] ? this.timeRange[0] : ""
            //     this.upDatavalue.end_time = this.timeRange[1] ? this.timeRange[1] : ""
            //     // console.log(this.upDatavalue);
            //     if (this.isEdit) {
            //         // 更新
            //         this.$http.put('/api/renew/' + this.upDatavalue.id, this.upDatavalue).then((res) => {
            //             console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
            //             if (res.code != 200) { //请求错误
            //                 this.$message.error(res.msg);
            //             }
            //             if (res.code == 200) {
            //                 this.show = false
            //                 this.$emit('refresh')
            //             }
            //         }).catch((err) => {
            //             console.log(err)
            //         });
            //     } else {
            //         // 新建
            //         this.$http.post('/api/renew', this.upDatavalue).then((res) => {
            //             console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
            //             if (res.code == 200) {
            //                 this.show = false
            //                 this.$emit('refresh')
            //             }
            //             if (res.code != 200) { //请求错误
            //                 this.$message.error(res.msg);
            //             }
            //         }).catch((err) => {
            //             console.log(err)
            //         });
            //     }
            // },


            // handleRemove(file, fileList) {
            //     console.log(file, fileList)
            //     this.dialogImageUrl = ''
            // },
            // handleAvatarSuccess(res, file) {

            //     console.log("res, fileres, fileres, fileres, file", res, file);

            //     this.dialogImageUrl = res.data
            //     console.log(this.dialogImageUrl)
            // },
            // beforeAvatarUpload(file) {
            //     const isLt2M = file.size / 1024 / 1024 < 2

            //     if (!isLt2M) {
            //         this.$message.error('上传头像图片大小不能超过 2MB!')
            //     }
            //     return /* isJPG && */ isLt2M
            // },


        }
    }
</script>


<style lang="scss" scoped>
    // .selfInputBox {
    // }

    /deep/.el-input__suffix {
        // border: 1px red solid !important;
        // box-sizing: content-box !important;
        position: relative;
        right: 10px;
    }


    /deep/.el-input__prefix {
        // border: 1px red solid !important;
        // box-sizing: content-box !important;
        position: absolute;
        left: 20px;
    }


    .el-select.el-select--mini,
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 100%;
    }


    .uploadImg {
        // border: 1px red solid !important;
        box-sizing: content-box !important;
        position: relative;
        right: -20px;
        width: 148px;
        height: 148px;
        overflow: hidden;

        // line-height: 30px;
        /deep/.el-upload--picture-card {
            // border: 1px red solid !important;
            // box-sizing: content-box !important;
        }

        /deep/.el-upload__text {
            line-height: 30px;
            text-align: center;
            // border: 1px red solid !important;
            // box-sizing: content-box !important;
        }

        /deep/.el-upload-dragger {
            width: 100%;
            height: 100%;
            // width: 148px;
            // height: 148px;
            // line-height: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }


    .infoContentBox {
        // border: 1px red solid !important;
        box-sizing: content-box !important;
        height: 500px;
        overflow-y: auto;

        .textLine {
            // border: 1px red solid !important;
            box-sizing: content-box !important;
            padding: 10px;

            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            align-content: flex-start;
            flex-wrap: wrap;


            .title {
                // border: 1px red solid !important;
                box-sizing: content-box !important;
                // display: inline-block;
                width: 100px;
            }

            .content {
                // border: 1px red solid !important;
                box-sizing: content-box !important;
                // display: inline-block;

                .Image {
                    width: 60px;
                    height: 60px;
                    // border: 1px red solid !important;
                    // box-sizing: content-box !important;
                }
            }

            .orderList {
                // border: 1px red solid !important;
                box-sizing: content-box !important;
                // display: inline-block;


                .order {
                    border: 2px dotted #D2D2D2 !important;
                    box-sizing: content-box !important;

                    margin-bottom: 10px;
                    padding: 10px;

                    width: 400px;


                }
            }
        }

        .back {
            // border: 1px red solid !important;
            // box-sizing: content-box !important;
            background-color: #F8F8F8;
        }

        // .back::before {
        //     content: "";
        //     border: 1px red solid !important;
        //     box-sizing: content-box !important;
        //     position: absolute;
        //     left: -50px;
        //     top: 0;
        //     width: 100%;
        // }



        .invoiceList {
            // border: 1px #D2D2D2 dotted !important;
            // border: 1px red dotted !important;
            box-sizing: border-box;
            // padding: 10px;

            width: calc(100% - 40px);
            height: 50px;

            margin: 20px;
            object-fit: cover;

        }






    }
</style>